/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "bootstrap/dist/css/bootstrap.min.css";

@font-face {
  font-family: 'poppins';
  src: url('assets/fonts/poppins-regular.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'lato-bold';
  src: url('assets/fonts/Lato-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'lato-regular';
  src: url('assets/fonts/Lato-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'sailec-l';
  src: url('assets/fonts/Sailec-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'sailec-b';
  src: url('assets/fonts/Sailec-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'sailec-t';
  src: url('assets/fonts/Sailec-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'sailec-m';
  src: url('assets/fonts/Sailec-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Kredit';
  src: url('assets/fonts/Inconsolata-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src: url('assets/fonts/icomoon.eot?vil9d9');
  src: url('assets/fonts/icomoon.eot?vil9d9#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.ttf?vil9d9') format('truetype'),
    url('assets/fonts/icomoon.woff?vil9d9') format('woff'),
    url('assets/fonts/icomoon.svg?vil9d9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-copy:before {
  content: "\e900";
}

.icon-hide:before {
  content: "\e901";
  cursor: pointer;
  margin-left: 5px;
  position: absolute;
  right: -8px;
  font-size: 20px;
}

.icon-view:before {
  content: "\e902";
  cursor: pointer;
  margin-left: 5px;
  position: absolute;
  right: 2px;
  font-size: 20px;
}

.form-control:focus {
  box-shadow: none;
}

.modal-footer {
  justify-content: center;
}

.btn-danger {
  color: #F53B43;
  background-color: transparent;
  border-color: #F53B43;
}

.alert-icon {
  display: none;
}

.alert-danger .alert-icon {
  display: inline-block;
  color: #F53B43;
  font-size: 18px;
  vertical-align: top;
  margin-right: 7px;
  margin-top: 4px;
}

.alert-danger .alert-msg {
  color: #F53B43;
  line-height: 1.5;
  vertical-align: middle;
  display: inline-block;
  // max-width: 310px;
  max-width: 275px;
}

.alert-danger {
  top: 10px;
  box-shadow: 0px -4px 4px rgb(0 0 0 / 25%);
  background-color: #fff;
  border: 1px solid #F53B43;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
}

.alert-success {
  background-color: #fff !important;
  border-color: #0e6bad !important;
  color: #0e6bad !important;
}

.btn-primary {
  color: #fff;
  background-color: #32ABE1;
  border-color: #32ABE1;
}

.modal-body {
  color: #6B6B6B;
}

h4.modal-title {
  color: #4A4363;
}

body {
  background-image: url('assets/img/bg1.png');
  position: relative;
  background-size: cover;
  background-position: top center;
}

body * {
  font-family: 'poppins';
}

.topSec>div {
  padding-left: 17px;
}

.logo {
  display: inline-block;
  margin: 23px 0 24px;
  max-width: 156px;
  max-height: 61px;
  height: 35px;
}

.wl-txt,
.wl-t-ybp {
  color: #fff;
  font-size: 32px;
  line-height: 37px;
  font-family: lato-regular;
}

.wl-t-ybp {
  margin-top: 10px;
}

.bottomSec {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 40px 40px 0 0;
  width: 420px;
}

.login-wrpr button.btn.btn-primary.my-4,
.login-wrpr button.mat-stroked-button.mat-accent {
  display: block;
  width: 100%;
  background-color: #32abe1;
  color: #fff;
  border-color: #32abe1;
  margin-bottom: 0 !important;
  padding: 0 12px;
  height: 45px;
  box-shadow: 0 0 4px rgba(0, 0, 0, .35);
  border-radius: 100px;
  font-size: 16px;
  font-family: sailec-m;
  outline: none !important;
}

.login-wrpr button.btn.btn-primary.my-4,
.login-wrpr button.mat-stroked-button.mat-accent .mat-button-wrapper {
  font-family: 'sailec-m';
}

.login-wrpr button.mat-stroked-button.resend-btn {
  display: inline-block;
  padding: 0;
  height: auto;
  background-color: transparent;
  color: red;
  border: 0;
  box-shadow: none;
  width: auto;
  text-decoration: underline;
  line-height: 1.1;
  font-size: 14px;
  float: right;
  padding-right: 60px;
  outline: none;
  margin-bottom: 15px !important;
  margin-top: 2px;
}

.login-wrpr button.mat-stroked-button.resend-btn span+span {
  display: none;
}

.login-wrpr {
  width: 100%;
  margin: 0 auto;
  padding: 42px 22px 22px;
}

img.next-icon {
  display: inline-block;
  margin-left: 0px;
  margin-bottom: 2.5px;
}

.login-wrpr .mat-form-field {
  display: block;
}

.login-wrpr .mat-form-field-infix {
  width: 100%;
}

.login-ttl {
  font-size: 20px;
  line-height: 23px;
  color: #4a4363;
  font-family: sailec-b;
  margin-bottom: 45px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  position: relative;
}

.app {
  position: absolute;
  left: 0;
  width: 420px;
  top: 0;
  bottom: 0;
  overflow-y: auto;
}

.background-img {
  position: absolute;
  left: 420px;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('assets/img/bg.jpg');
  background-size: cover;
  background-position: center;
}

.login-wrpr .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
  border: 0;
}

.login-wrpr .mat-select .mat-select-trigger {
  height: 35px;
}

.login-wrpr .mat-select .mat-select-trigger .mat-select-value {
  vertical-align: middle;
}

.login-wrpr .mat-form-field-appearance-legacy .mat-form-field-infix .error-wrpr,
.error-wrpr {
  position: absolute;
  font-size: 12px;
  bottom: -15px;
}

.passcode-strg-lst+.passcode-strg-lst {
  margin-top: 4px;
}

.passcode-strg-lst:before {
  content: '';
  width: 15px;
  height: 15px;
  border: 1px solid #DDE9EE;
  position: absolute;
  left: 0;
  top: 2px;
  border-radius: 50%;
}

.passcode-strg-lst.active:before {
  background: #72D045;
}

.passcode-strg-lst.active:after {
  content: '';
  width: 7px;
  height: 4px;
  border-left: 1px solid #fff;
  position: absolute;
  border-bottom: 1px solid #fff;
  top: 6px;
  left: 4px;
  transform: rotate(-36deg);
}

.login-wrpr .mat-form-field-appearance-legacy .mat-form-field-infix .error-wrpr .mat-error {
  color: #f44336;
  font-family: Roboto, Helvetica Neue, sans-serif;
}

.login-wrpr .mat-form-field-label-wrapper {
  top: -10px;
}

.login-wrpr button.btn.btn-primary.my-4 {
  margin-bottom: 0 !important;
}

.login-previous-icon {
  top: 15px;
  left: -12px;
}

.login-wrpr .mat-form-field-appearance-legacy .mat-form-field-label .mat-error,
.login-wrpr .mat-select-value-text .mat-error,
.login-wrpr .mat-input-element .mat-error {
  color: #f44336;
}

.login-wrpr .mat-form-field-appearance-legacy .mat-form-field-label mat-label,
.login-wrpr .mat-input-element,
.login-wrpr .mat-select-value-text mat-label {
  font-size: 14px;
  line-height: 16px;
  color: #4a4363;
  font-family: 'sailec-m';
}

.login-wrpr .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 19px;
}

.login-wrpr .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #ececec;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #58a9dc;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #e15241;
}

.bottomSec .login-wrpr .otp-input {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #6c6c6b;
  font-family: 'poppins';
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #dde9ee;
  outline: none !important;
}

.bottomSec .login-wrpr .form-group-otp .otp-input {
  border-radius: 4px;
  border: 1px solid #c5c5c5;
  font-family: 'sailec-l';
  font-weight: normal;
  color: #000;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow,
.mat-select-arrow {
  color: #e15241;
}

.bottomSec .login-wrpr .otp-input:focus {
  border-color: #58a9dc;
}

@media(min-width:768px) {
  .modal-dialog {
    margin: 48px 33px;
    max-width: 350px;
  }

  body {
    background-size: 420px 100vh;
    background-position: top left;
    background-repeat: no-repeat;
    background-color: #18a0de;
  }
}

@media(max-width: 767px) {
  .modal-dialog {
    margin: 48px 33px;
  }

  .login-wrpr {
    max-width: 100%;
    padding: 42px 25px;
  }

  .topSec>div {
    text-align: left;
    padding: 0 7px;
  }

  app-login {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  app-login .row {
    min-height: 100%;
  }

  .background-img {
    display: none;
  }

  .app {
    width: auto;
    position: static;
  }

  .bottomSec {
    width: auto;
    right: 0;
  }

  .wl-txt,
  .wl-t-ybp {
    font-size: 25px;
    line-height: 35px;
  }

  .login-previous-icon {
    left: -16px;
  }

  .alert.alert-box-with-title .alert-box-inner {
    margin: 65px auto 0;
  }
}

@media(max-width: 359px) {
  .login-wrpr {
    padding: 25px 30px;
  }
}

.alert-position {
  position: absolute;
  width: 93%;
  z-index: 999;
  left: 0;
  right: 0;
  margin: 0 auto;
  // transform: translateY(calc(0px - 100%));
  // transition: all .5s cubic-bezier(.68,-.55,.25,1.35);
}

// .alert {
//   transform: translateY(-200%);
//   transition: all ease 0.3s;
// }

// .alert.toggled {
//   transform: translateY(0);
// }

.model-cross-icon {
  outline: none !important;
}

.h-100 {
  height: 100%;
}

.h-90 {
  height: 90%;
}

.h-85 {
  height: 85%;
}

.w-100 {
  width: 100% !important;
}

.w-95 {
  width: 95% !important;
}

.modal-header {
  padding: 10px;
  padding-bottom: 0;
  height: 45px;
}

.modal-footer {
  padding: 3px 10px;
}

.info-modal {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}

@media (min-width: 768px) {
  .img-modal[_ngcontent-nag-c86] {
    width: 420px !important;
  }
}

.info-modal-content {
  margin: auto;
  display: block;
  width: 95%;
  max-width: 420px;
}

.info-modal-content {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }

  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

.info-close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 35px;
  font-weight: bold;
  transition: 0.3s;
}

.info-close:hover,
.info-close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

.kitSuggetion2 {
  width: 5%;
  text-align: center;
  position: relative;
  top: 8px;
  color: #000;
  border-radius: 2px;
  cursor: pointer;
  font-size: 20px;
}

.loadingSplinner {
  height: 16px;
  width: 16px;
  font-size: 12px;
  margin-bottom: 1px !important;
  margin-left: 3px !important;
}

.ot-none {
  outline: none !important;
}

.otp-sec-ht .mat-form-field {
  margin-top: 10px;
}

.bt-mt-14 {
  margin-top: 14px !important;
}

.set-pin-body {
  padding-top: 10px;
}

.set-pin-body .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
}

.set-pin-body input.mat-input-element {
  height: 30px;
}

.set-pin-body .new-pin-input {
  font-size: 25px;
}

.set-pin-body .mt-10 {
  margin-top: 10px;
}

.small-loader {
  // color: #fff !important;
  width: 1rem !important;
  height: 1rem !important;
}

.passcode-strg-lst {
  position: relative;
  padding: 0 0 0 22px;
}

.passcode-strg-chk {
  padding: 0 0 20px;
}

.passcode-lable {
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #494361;
}

.passcode-tggle {
  position: absolute;
  bottom: 9px;
  left: 212px;
  width: 20px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passcode-inp-wrpr {
  position: relative;
}

app-menu,
app-card-detail,
app-add-funds,
app-card-transaction {
  height: 100%;
  display: block;
}

.add-funds.login-wrpr .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 18px;
}

.add-funds.login-wrpr .mat-form-field-prefix {
  font-size: 14px;
  line-height: 16px;
  color: #4A4363;
}

.add-funds.login-wrpr .mat-form-field-prefix span {
  font-family: 'sailec-m';
}

.add-funds {
  padding: 40px 25px 10px;
}

.dot-flashing {
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #214671;
  color: #214671;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -22px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #214671;
  color: #214671;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 22px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #214671;
  color: #214671;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

.stage-dot-flashing {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

.toggle-checkbox label,
.toggle-checkbox label:before {
  width: 37px;
  height: 18px;
  display: block;
  margin: 0;
  background-image: url('assets/img/toggle-off.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
}

.toggle-checkbox label:before {
  content: '';
  position: absolute;
  visibility: hidden;
  background-image: url('assets/img/toggle-on.svg');
}

.toggle-checkbox input:checked+label {
  background-image: url('assets/img/toggle-on.svg');
}

.setting-slider .mat-slider {
  width: 100%;
  height: 35px;
}

.setting-slider .mat-slider-thumb {
  background-color: #fff !important;
  border-color: #e24c4c !important;
}

.setting-wrpr .mat-slider-horizontal .mat-slider-ticks {
  background-image: none;
  background-color: #1f0a3a !important;
}

.setting-wrpr .mat-slider-track-background {
  background-color: #e1dee5;
}

.setting-slider .mat-accent .mat-slider-track-fill,
.setting-slider .mat-accent .mat-slider-thumb,
.setting-slider .mat-accent .mat-slider-thumb-label {
  background-color: #e24c4c;
}

.mat-slider-thumb:before {
  content: '';
  position: absolute;
  left: 3px;
  top: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #12355F;
}

.setting-slider .mat-slider:not(.mat-slider-disabled).cdk-mouse-focused .mat-slider-thumb:before,
.setting-slider .mat-slider:not(.mat-slider-disabled).cdk-touch-focused .mat-slider-thumb:before,
.setting-slider .mat-slider:not(.mat-slider-disabled).cdk-program-focused .mat-slider-thumb:before {
  left: 4px;
  top: 4px;
}

.alert-modal {
  position: absolute;
  ;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
}

.backdrop,
.loader-backdrop {
  position: fixed;
  background: #212529;
  opacity: 0.6;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.alert-modal-content {
  position: relative;
  background-color: #fff;
  margin: 65px 40px 0;
  border-radius: 5px;
}

.alert-modal-head span {
  width: 10px;
  height: 10px;
  position: relative;
}

.alert-modal-head {
  padding: 9px 15px 11px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #494361;
  border-bottom: 1px solid #E1DEE5;
}

.alert-modal-head span:before,
.alert-modal-head span:after {
  width: 10px;
  content: '';
  height: 2px;
  transform: rotate(45deg);
  position: absolute;
  background-color: #000;
  top: 5px;
  left: 0;
  cursor: pointer;
}

.alert-modal-head span:after {
  transform: rotate(135deg);
}

.alert-modal-body {
  padding: 20px 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #6C6C6B;
  text-align: justify;
}

.alert-modal-footer {
  padding: 8px 15px;
  border-top: 1px solid #E1DEE5;
}

.alert-modal-footer-btn {
  background: #58A9DC;
  border-radius: 8px;
  width: 48%;
  color: #fff;
  height: 45px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
}

.alert-modal-footer-btn.disabled {
  pointer-events: none;
}

.alert-modal-footer-btn.alert-modal-footer-btn-cancel {
  background-color: transparent;
  border: 1px solid #D61F2C;
  color: #D61F2C;
}

.login-wrpr .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  max-height: 51px;
}

.payment-wrpr .mat-form-field-flex {
  align-items: center;
}

@keyframes dotFlashing {
  0% {
    background-color: #eee;
  }

  50%,
  100% {
    background-color: #214671;
  }
}

.login-wrpr-kyc.login-wrpr .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  top: 24px;
}

.login-wrpr-kyc input::-webkit-outer-spin-button,
.login-wrpr-kyc input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.login-wrpr-kyc input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.main-panel>div {
  min-height: 100%;
}

.bottomSec .login-wrpr-kyc .mat-button-focus-overlay,
.bottomSec .login-wrpr-kyc .mat-button-ripple.mat-ripple {
  display: none;
}

.bottomSec .login-wrpr-kyc .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button {
  outline: none;
  background-image: url('assets/img/calendar.svg');
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
  border-radius: 0;
}

.bottomSec .login-wrpr-kyc .mat-form-field-appearance-legacy .mat-form-field-suffix .mat-icon-button .mat-button-wrapper {
  display: none;
}
.bg-blue-wrpr {padding: 20px;margin: 50px 25px 0;border-radius: 12px;background-color: #ecf4ff;}
.bg-blue-wrpr+.bg-blue-wrpr {margin-top: 29px;}
.upi-info-wrpr {display: flex;}
.upi-info-icon {padding-right: 20px;}
.upi-info-icon img {display: block;}
.upi-info-image {padding: 20px 0 0;}
.upi-info-image img {display: block;max-width: 100%;}
.chalo-footer {position: fixed;bottom: 0;width: 420px;background-color: #fff;}
.upi-img-wrpr {display: flex;align-items: center;justify-content: center;padding: 19px 0;}
.back-btn-footer {color: #57a9dc;text-align: center;font-size: 16px;font-weight: 600;line-height: 145%;padding: 13px 0;box-shadow: 0 -4px 10px #00000029;background-color: #fff;cursor: pointer;font-family: 'Roboto';}
.chalo-pg-wrpr{padding-bottom: 130px}
.upi-info-dcrb,.upi-info-dcrb a {color: var(--font-black, #000);font-size: 14px;font-style: normal;font-weight: 500;line-height: normal;font-family: 'sailec-m';}
.upi-info-dcrb a{text-decoration: underline;}
.wlt-blnc span {
    color: #6C6C6B;
    font-size: 12px;
    line-height: inherit;
}

.wlt-blnc {
    display: flex;
    align-items: center;
}

.wlt-blnc img {
    margin-right: 6px;
}
@media(min-width: 768px) {
  #confirm-modal .alert-modal-content {
    max-width: 340px;
  }
}

@media(max-height: 600px) {
  .qr-img-wrpr canvas {
    width: 30vh !important;
    height: 30vh !important;
  }
}

@media(max-width: 767px) {
  .alert-modal-content {
    margin: 60px auto;
    max-width: calc(100% - 80px);
  }
  .chalo-footer{width: 100%;}
}

span.rupee-symbol {
  font-family: 'sailec-b';
}

.arrow-down:before,
.arrow-up:before {
  border-left: 5.5px solid transparent;
  border-right: 5.5px solid transparent;
  border-top: 5.5px solid #212529;
  display: inline-block;
  content: '';
  line-height: 1;
  position: absolute;
}

.arrow-up:before {
  border-top: 0;
  border-bottom: 5.5px solid #212529;
}

.alert.alert-box-with-title {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent !important;
  padding: 0;
  margin: 0;
}

.alert.alert-box-with-title {
  .alert-box-inner {
    background-color: #fff;
    border-radius: 5px;
    max-width: 380px;
    position: relative;
    margin: 65px 20px 0;
  }

  .alert-box-with-title-bg {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #212529;
    opacity: 0.6;
  }

  .alert-title-message {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #494361;
    padding: 10px 20px;
    border-bottom: 1px solid #E1DEE5;
  }

  .alert-box-inner .alert-msg {
    padding: 20px;
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #6C6C6B;
  }

  .alert-box-inner .close.alert-title-close {
    position: absolute;
    top: 13px;
    right: 16px;
    font-weight: normal;
    line-height: 1;
    font-size: 26px;
    opacity: 1;
  }
}

